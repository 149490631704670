.journalTabContainer {
  position: relative;
  background: var(--white);
  margin-bottom: 8px;
  margin-left: 16px;
  display: inline-block;
}

.createDocumentContainer {
  float: right;
  position: relative;
  right: 16px;
}

.journalTab {
  width: 78px;
  border-bottom-width: 3px;
  text-transform: none;
  padding: 0;

  &:last-child {
    width: 110px;
  }
}

.mobileContainer {
  display: flex;
  justify-content: center;

  .journalTab {
    margin-top: 0;
  }
}
