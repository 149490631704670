.container {
  display: grid;
  grid-template-rows: max-content 1fr max-content;
  height: 100%;
  overflow: auto;
}

.documentListContainer {
  overflow: auto;
  margin-bottom: 10px;

  @media (min-width: 640px) {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.actionBtnContainer {
  display: flex;
  justify-content: end;
  gap: 16px;
  padding: 16px;
}
