.label {
  color: var(--grey-semi-dark);
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
}

.text {
  color: var(--mine-shaft);
  font-weight: 500;
  letter-spacing: 0.25px;
  min-height: 21px;
}
