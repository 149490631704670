.journalFeed {
  height: 100%;
}

@media screen and (min-width: 640px) {
  .journalFeed {
    display: grid;
    margin: 0 16px;
  }

  .withFeedInput {
    grid-template-rows: min-content 1fr;
  }
}
