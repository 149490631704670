.tooltip {
  width: max-content;
  background: var(--black);
  color: white;
  font-weight: bold;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 90%;
  z-index: 2000;

  .content {
    display: flex;
    align-items: center;
    gap: 4px;

    &:hover {
      cursor: pointer;
    }
  }

  .arrow {
    background: var(--black);
    visibility: hidden;

    &::before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      content: '';
      transform: rotate(45deg);
    }
  }
}
