.content {
  display: grid;
  height: 100vh;
  grid-template-columns: 1fr 421px 1fr;
  grid-template-rows: repeat(3, 1fr);

  .error {
    text-align: center;
    grid-area: 2 / 2 / 2 / 2;
  }

  .logo {
    grid-area: 1 / 2 / 2 / 2;
    margin: 43px auto 0;

    svg {
      width: 72px;
      height: 44px;
    }
  }

  .studentLogo {
    grid-area: 1 / 2 / 2 / 2;
    margin: 43px auto 0;

    svg {
      width: 72px;
      height: 44px;
      color: var(--green-primary);
    }

    .math {
      font-weight: 700;
      font-size: 25.1058px;
      line-height: 27px;
      color: var(--green-primary);

      margin: 0;
    }
  }

  .warningSign {
    margin-bottom: 14px;
    color: #ee7575;

    svg {
      width: 32px;
      height: 32px;
    }
  }
  .oops {
    font-weight: 700;
    font-size: var(--twenty-pixels-font-size);
    line-height: 24px;
  }

  .tryAgain {
    cursor: pointer;
    background: var(--green-primary);
    color: var(--white);
    padding: 10px 20px 10px 20px;
    border: none;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    text-decoration: none;
  }
}

.student {
  color: var(--white);
  background: var(--haiti);
}

.errorText {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-bottom: 10px;

  .mailHyperlink {
    cursor: pointer;
    color: var(--green-primary);
    text-decoration: underline;
  }
}
