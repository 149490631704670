.mentionsMenu {
  position: relative;
  width: 200px;
  max-height: 200px;
  z-index: 1301;
  box-shadow: none;
  border: 1px solid var(--grey-light);
  border-bottom: 1px solid var(--grey-light);
  margin: 25px 5px 0;
  padding-top: 10px;
  color: var(--black);
  background: var(--white);
  overflow: auto;
}

.mentionItem {
  font-size: 14px;
  font-weight: 500;
}

.selectedMentionItem {
  color: var(--green-primary);
}
