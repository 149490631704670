.postMenuContainer {
  cursor: pointer;
  margin-left: auto;
  display: flex;

  .postMenu {
    display: flex;
    color: var(--grey-semi-dark);
    position: relative;

    .forReviewIndicator {
      visibility: visible;
      display: flex;
      align-items: center;
      width: 95px;

      img {
        margin-right: 5px;
      }

      .forReviewIndicatorText {
        color: var(--green-primary);
        @media (max-width: 768px) {
          font-size: 12px;
        }
      }
    }
  }

  .replyIcon {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: -30px;
    right: 20px;
    color: var(--grey-light);

    .replySvg {
      width: 18.01px;
      color: var(--alto);
    }

    .replyCount {
      color: var(--mine-shaft);
    }
  }
}
