.root {
  padding: 0 16px 16px;

  &:first-of-type {
    padding-top: 16px;
  }
}

.anchor {
  display: block;
  text-decoration: none;
  border: 1px solid var(--alto);

  &:visited {
    color: inherit;
  }
}

.resourceContainer {
  display: flex;
  align-items: center;
  padding: 13px 0 13px 12px;
}

.title {
  font-size: 14px;
  font-weight: bold;
  color: var(--shark);
  padding-left: 18px;
}

.icon {
  display: flex;
  color: var(--green-primary);
}
