.ribbonContainer {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 1%;
  overflow-x: auto;
  flex-shrink: 0;
  background-color: var(--white);

  @media screen and (min-width: 640px) {
    padding: 0 16px 8px;
  }
}

.printRibbonContainer {
  display: flex;
  gap: 0.5rem;
  background-color: var(--white);
  padding: 0 32px 16px;
}
