.driveButton {
  height: 42px;
  display: flex;
  flex-direction: column-reverse;

  @media screen and (min-width: 640px) {
    flex-direction: row;
  }
}

.googleDriveIcon {
  margin-left: 8px;
  vertical-align: middle;
}
