button.createFileBtn {
  height: 42px;
}

.googleDriveIcon {
  margin-left: 15px;
  vertical-align: middle;
}

.createFileDialog {
  .googleDriveIcon {
    width: 20px;
    height: 20px;
  }

  .fileNameControl {
    width: 100%;
    height: 80px;
    margin-top: 5px;
  }

  .fileIcon {
    vertical-align: middle;
    margin-right: 10px;
  }
}

@media only screen and (min-width: 640px) {
  button.createFileBtn {
    right: 0;
  }
}
