.profilePicture {
  margin-right: 8px;
}

.userInfo {
  display: flex;
  align-items: center;

  .userName {
    font-size: 16px;
    font-weight: 700;

    .displayName {
      @media (max-width: 640px) {
        display: inline-block;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .timeAgo {
    color: var(--grey-semi-dark);
    font-size: 12px;
  }
}
