.root {
  background: var(--mercury);
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: max-content 1fr;
}

.container {
  margin-top: 10px;
  background: var(--white);
  padding: 16px 24px;
  overflow: auto;

  img {
    max-width: 100%;
    height: auto;
    object-fit: contain;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  margin: 0;
  font-size: var(--twenty-pixels-font-size);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.15px;
}

.subTitle {
  margin: 10px 0 0;
  font-size: 12px;
  text-transform: capitalize;
  color: var(--grey-semi-dark);
}

.arrowBack {
  cursor: pointer;
  margin-top: 5px;
  margin-right: 15px;
}

.titleAndButtonWrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
