.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    color: var(--green-primary);
    font-weight: 700;
    font-size: 14px;
    text-align: center;
  }
}
