.uploadButtonsContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 8px;
  opacity: 0.6;

  .uploadButton {
    padding: 4px;
    min-width: unset;
  }
}
