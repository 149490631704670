.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: var(--white);
}

.addUsers {
  background: var(--white);
  padding: 16px;

  .title {
    margin: 0;
    color: var(--grey-semi-dark);
  }

  .description {
    margin: 0;
    color: var(--mine-shaft);
  }

  .textField {
    width: 420px;
  }
}

.listUsersContainer {
  background: var(--alabaster);
  padding-top: 24px;
  overflow: auto;

  .listUsers {
    height: 100%;
    background: var(--white);
    padding: 16px;
    max-height: 505px;
    overflow: auto;

    .listUsersTitle {
      margin: 0;
      text-transform: uppercase;
    }

    .pill {
      background: transparent;
      border: 1px solid var(--mine-shaft);
      margin-right: 16px;
      margin-bottom: 16px;
      height: 24px;

      svg {
        color: var(--mine-shaft);

        &:hover {
          background-color: var(--white);
        }
      }
    }

    .redPillAndError {
      margin-bottom: 16px;

      .incorrectEmailPill {
        background: transparent;
        border: 1px solid var(--hot-cinnamon);
        color: var(--hot-cinnamon);
        margin-right: 16px;
        height: 24px;

        svg {
          color: var(--hot-cinnamon);

          &:hover {
            background-color: var(--white);
          }
        }
      }

      .errorMessage {
        color: var(--hot-cinnamon);
        margin-bottom: 16px;
      }
    }
  }
}

.emailInputContainer {
  display: flex;
  align-items: center;

  .loadingIndicator {
    display: inline-block;
    vertical-align: bottom;
    margin-left: 6px;
  }
}

.hidden {
  visibility: hidden;
}
