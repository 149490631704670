.contentContainer {
  height: calc(var(--vh) * 100);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5% 0;

  .logo {
    font-size: 110px;
  }

  .welcome {
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0.18px;
    padding-bottom: 10px;
    text-align: center;
  }

  .text {
    font-size: 14px;
    letter-spacing: 0.25px;
    text-align: center;
    max-width: 500px;
  }

  .loginImg {
    margin: 10px auto 14px;
    display: block;
    object-fit: cover;

    @media (min-width: 640px) {
      width: clamp(322px, 48vw, 644px);
      height: clamp(267px, 40vh, 534px);
    }
  }

  .policy {
    display: flex;
    justify-content: center;
    padding-top: 30px;

    .policyButton {
      color: var(--black);
      font-weight: normal;
      text-transform: capitalize;
      letter-spacing: 0.25px;
      text-decoration-line: underline;
    }
  }
}

.loginWrapperTeacher {
  height: 100%;
  overflow-y: auto;
  background: var(--white);
}

.loginWrapperStudent {
  height: 100%;
  overflow-y: auto;
  background: var(--teacher-nav-background-color);

  .welcome {
    color: var(--white);
  }

  .text {
    color: var(--white);
  }

  .policy {
    .policyButton {
      color: var(--white);
    }
  }
}
