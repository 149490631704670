.toolbarItem {
  opacity: 0.6;
  padding: 4px;
  min-width: unset;

  &.active {
    background: var(--alto);
    opacity: 1;
  }
}
