.typeaheadPopover {
  min-width: 200px;
  z-index: 1;
}

.emojiItem {
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
}

.selectedEmojiItem {
  background: var(--grey-light);
}
