.headerButtons {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.hiddenHeaderButtons {
  display: flex;
  align-items: center;
}

.headerVisibilityButtonContainer {
  display: flex;
  margin-right: 5px;
  cursor: pointer;
  align-items: center;

  .headerVisibilityButtonTitle {
    font-weight: 500;
    font-size: 14px;
  }
}
