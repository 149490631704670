.dialogContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .dialogWrapper {
    top: 50%;
    width: 100%;
    background: var(--white);
    border-radius: 10px;
    padding: 8px;

    @media (min-width: 640px) {
      width: 540px;
      padding: 16px;
    }

    .filesContainer {
      max-height: 300px;
      overflow: auto;
    }

    .userInfo {
      display: flex;
      margin: 16px 0;

      .userName {
        font-weight: 600;
        font-size: 16px;
        color: #313e47;
        margin-bottom: 2px;
      }

      .timeAgo {
        color: var(--grey-semi-dark);
        font-size: 12px;
      }
    }

    .profilePicture {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      margin-right: 12px;
    }

    .modalButtons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 10px;
    }
  }
}
