.banner {
  display: flex;
  flex-direction: column;
  height: 142px;
  min-height: 42px;

  .toolbarMessage {
    padding: 12px 0;
    line-height: 20px;
    text-align: center;
    color: var(--white);
    font-weight: bold;
  }

  .closeIcon {
    position: absolute;
    bottom: 0;
    right: 0;
    color: var(--white);
  }

  @media (min-width: 640px) {
    flex-direction: row;
    height: 42px;

    .toolbarMessage {
      padding-right: 24px;
      padding-left: 24px;
    }

    .closeIcon {
      right: 24px;
    }
  }
}
