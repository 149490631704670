@mixin editor-theme {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  .cursorPointer {
    cursor: pointer;
  }

  .textUnderline {
    text-decoration: underline;
  }

  .textStrikethrough {
    text-decoration: line-through;
  }

  .textUnderlineStrikethrough {
    text-decoration: underline line-through;
  }

  .textBold {
    font-weight: bold;
  }

  .textItalic {
    font-style: italic;
  }
}

.rootOutlinedEditor {
  outline: none;
  padding: 8px 8px 40px;
  min-height: 40px;

  @include editor-theme;
}

.rootMobileEditor {
  outline: none;
  padding: 8px 8px 40px;
  min-height: 80px;

  @include editor-theme;
}

.rootStandardEditor {
  outline: none;
  background: #ffffff;
  box-shadow: inset 0px -2px 0px #220f2d;
  border-radius: 4px 4px 0 0;
  padding: 12px 38px 16px 16px;

  @include editor-theme;
}

.rootReadonlyEditor {
  @include editor-theme;
}
