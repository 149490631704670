.stepperContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
}
.divider,
.darkDivider {
  margin-top: 16px;
  height: 0px;
  width: 112px;
  position: relative;
  z-index: 10;
}
.divider {
  border: 1px solid var(--grey-light);
}
.darkDivider {
  border: 1px solid var(--grey-semi-purple);
}
.fragmentWrapper {
  display: flex;
  flex-direction: row;
}
.darkIcon,
.lightIcon {
  margin-left: -4px;
  margin-right: -4px;
}
.darkIcon {
  color: var(--grey-semi-purple);
}
.lightIcon {
  color: var(--grey-light);
}
