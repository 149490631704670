.root {
  border: 1px solid rgba(157, 157, 157, 0.2);
  border-radius: 8px;
  padding: 2px 8px;

  .fileInfoContainer {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    min-height: 40px;

    .fileInfo {
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      align-items: center;
      text-decoration: none;

      .fileIcon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      .fileName {
        font-weight: 500;
        letter-spacing: 0.1px;
        line-height: 24px;
        color: var(--cod-gray);
        opacity: 0.8;
        margin-right: 4px;
      }

      .fileSize {
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        text-transform: uppercase;
        color: var(--cod-gray);
        opacity: 0.5;
        margin-right: 4px;
      }
    }

    .removeFile {
      color: var(--mine-shaft);
    }
  }
}
