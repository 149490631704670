.tab {
  color: var(--grey-text);

  &:hover {
    color: var(--white);
    background: inherit;
  }
}

.tabRoot {
  flex-direction: row;
  column-gap: 14px;
  padding: 20px 17px 18px;
  text-transform: none;

  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
    column-gap: 0;
    padding: 0;
    font-size: 12px;
  }
}

.badge {
  top: -3px;
  right: -2px;
  height: 16px;
  font-size: 11px;
  min-width: 6px;
  border-radius: 4px;
  padding: 0 2px;

  @media (max-width: 640px) {
    top: 10px;
  }
}
