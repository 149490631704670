.emojiSelector {
  background: var(--black);
  border-radius: 22.0253px;
}

.emojiReactionsWrapper {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.insertEmoticonIcon {
  margin-right: 10px;
  padding: 2px;
  border: 1.08px solid rgba(0, 0, 0, 0.1);
}
