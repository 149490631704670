.contentContainer {
  display: flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
}

.profilePicture {
  margin-right: 8px;
}

.displayName {
  font-size: 16px;
  font-weight: 700;
}

.menuContainer {
  margin-left: auto;
}

.message {
  font-size: 16px;
  color: var(--river-bed);
}

// Truncate for multiline comments
.truncateOverflow {
  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  font-size: 14px;
  line-height: 1.4;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
