.reviewButton {
  user-select: none;
  border: 1px solid var(--grey-semi-dark);
  border-radius: 30px;
  padding: 5px 10px;
  font-size: 12px;
  font-weight: 500;
  font-family: 'Graphik', sans-serif;
  cursor: pointer;
  color: var(--grey-semi-dark);
  background: transparent;
}

.active {
  background: var(--panache);
  border-color: var(--green-primary);
  color: var(--green-primary);
}
