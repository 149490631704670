@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

.container {
  display: flex;
  align-items: center;
  height: 40px;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%);
  cursor: pointer;

  :hover {
    box-shadow: 0 0 3px 3px rgb(66 133 244 / 30%);
  }

  :active .labelContainer {
    background-color: var(--mariner);
  }

  .googleIconContainer {
    border: 1px solid var(--cornflower-blue);
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    background-color: var(--white);
  }

  .labelContainer {
    align-self: stretch;
    display: flex;
    align-items: center;
    background-color: var(--cornflower-blue);
    font-weight: bold;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    width: 100%;
    padding-left: 13px;
    padding-right: 9px;
  }

  .label {
    color: var(--white);
    font-family: 'Roboto', sans-serif;
  }
}
