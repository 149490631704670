.feedOutputWrapper {
  position: relative;
  padding: 16px;
  border-bottom: 1px solid var(--alto);

  &.teacherPost {
    background: var(--panache);
  }

  .message {
    padding: 8px 4px;
    font-size: 16px;
  }

  .reactionsContainer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .viewCommentsButton {
    padding: 4px;
    font-weight: 500;
    text-transform: none;
    letter-spacing: 0.25px;
  }

  .userAvatarContainer {
    display: flex;
    align-items: center;
  }

  .journalLink {
    margin-left: 0;
    margin-right: 0;
    cursor: pointer;
  }
}
