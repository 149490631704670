.label {
  font-weight: 600;
}

.formControl {
  margin-bottom: 5px;
}

.radioBtn {
  margin-left: 8px;
}
