.documentRow {
  padding: 16px;
  border: 1px solid var(--alto);
  box-shadow: 0px -1px 0px 0px var(--athens-gray) inset;

  .fileTitleContainer {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr max-content;

    .fileImageContainer {
      display: flex;
      align-items: center;
      position: relative;
    }

    .fileIcon {
      width: 22px;
      height: 22px;
    }

    .uploadFileIcon {
      background: var(--black);
      border-radius: 50%;
      font-size: 10px;
      padding: 2px;
      position: absolute;
      bottom: -2px;
      right: -8px;
    }

    .descriptionIcon {
      color: var(--royal-blue);
    }

    .fileName {
      display: inline-block;
      margin-left: 12px;
      color: var(--charade);
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }

    .fileNameButton {
      background: none;
      border: none;
      padding: 0;
      text-align: left;
      color: var(--charade);
      outline: inherit;
      font-size: 14px;
    }
  }

  .tagsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 12px;

    .tag {
      height: 24px;
    }
  }

  .fileFooterContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 12px;
    font-size: 10px;
    color: var(--gray);
  }

  .deleteButton {
    margin: -8px -8px -8px 0;
  }

  .markForReviewContainer {
    padding-top: 12px;

    .markForReviewBtn {
      width: 100%;

      @media (min-width: 640px) {
        width: unset;
      }
    }
  }
}
