.markForReviewContainer {
  position: absolute;
  left: 8px;
  bottom: 62px;
}

.customPluginContainer {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
