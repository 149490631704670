@use 'sass:math';

$fillColor: var(--green-primary);
$borderGreen: var(--green-primary-700);
$lightGreen: var(--panache);
$background: var(--white);
$border: var(--silver);
$initialSelectedBorder: var(--grey-semi-dark);
$completedSelectedBorder: var(--green-primary-700);

$titleHeight: 24px;
$subTitleHeight: 16px;
$contentHeight: $titleHeight + $subTitleHeight;
$fourthOfContentHeight: math.div($contentHeight, 4);
$thirdOfContentHeight: $fourthOfContentHeight * 3;

.ribbonTitle {
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: $titleHeight;
  color: var(--grey-semi-dark);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ribbonSubTitle {
  color: var(--grey-semi-dark);
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: $subTitleHeight;
  font-weight: normal;
}

.initialPhase {
  color: var(--haiti);
  background-color: $background;
  border-top: 1px solid $border;
  border-bottom: 1px solid $border;
}

.activePhase {
  color: var(--white);
  font-weight: bold;
  background-color: $fillColor;

  .ribbonSubTitle {
    color: var(--white);
  }
}

.initialPhase.ribbonItem {
  position: relative;
  min-width: 160px;
  padding: $fourthOfContentHeight $fourthOfContentHeight $fourthOfContentHeight
    24px;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }

  @media (max-width: 640px) {
    padding: $fourthOfContentHeight - 5px $fourthOfContentHeight
      $fourthOfContentHeight - 5px 24px;
  }
}

.arrowLeftCurrent:before {
  border-bottom: $thirdOfContentHeight solid transparent;
  border-left: $fourthOfContentHeight solid $background;
  border-top: $thirdOfContentHeight solid transparent;
  bottom: 0;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.afterActive:after {
  background: $fillColor;
}

.arrowLeftOutline:before {
  border: solid $border;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg) skew(-23deg, -26deg)
    translate(-(math.div($contentHeight, 2)), -(math.div($contentHeight, 2)));
  bottom: 0;
  content: '';
  height: 0;
  left: 0;
  position: absolute;
  top: 14px;
  width: 0;
}

.arrowRightOutline:after {
  border: solid $border;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 14px;
  transform: rotate(-45deg) skew(-23deg, -26deg)
    translate(math.div($contentHeight, 2), math.div($contentHeight, 2));
  bottom: 0;
  content: '';
  height: 0;
  right: 0;
  position: absolute;
  top: 16px;
  width: 0;
}

.arrowLeftOutlineBorder {
  border-left: 1px solid $border;
}

.arrowRightOutlineBorder {
  border-right: 1px solid $border;
}

.selectedInitialPhase {
  border-color: $initialSelectedBorder;
  border-bottom: 1px solid $initialSelectedBorder;
  &:before,
  &:after {
    border-color: $initialSelectedBorder;
  }
}

.completedPhase {
  background-color: $lightGreen;
  border-color: $lightGreen;

  .ribbonTitle {
    color: var(--green-primary);
  }
}

.afterCompleted:after {
  background: $lightGreen;
  border-color: $lightGreen;
}

.beforeCompletedActive:before {
  background: var(--white);
  border-color: var(--white);
}

.selectedInitialPhase {
  font-weight: 700;
  border-color: $initialSelectedBorder;
  border-bottom: 1px solid $initialSelectedBorder;
  &:before,
  &:after {
    border-color: $initialSelectedBorder;
  }

  .ribbonTitle {
    color: var(--haiti);
  }
}

.selectedActivePhase {
  font-weight: 700;
  border-color: $fillColor;
  &:before,
  &:after {
    border-color: $fillColor;
  }

  .ribbonTitle {
    color: var(--white);
    font-weight: normal;
  }
}

.selectedCompletedPhase {
  font-weight: 700;
  color: $fillColor;
  border-color: $completedSelectedBorder;
  &:before,
  &:after {
    border-color: $completedSelectedBorder;
  }

  .ribbonSubTitle {
    color: var(--haiti);
  }
}
