.tabsRoot {
  min-width: 72px;
  text-transform: none;
  padding: 4px;
  padding-top: 11px;
}

.indicator {
  display: flex;
  justify-content: center;
  background-color: transparent;

  span {
    max-width: 72px;
    width: 100%;
    background-color: var(--green-primary);
  }
}

.root {
  display: grid;
  grid-template-columns: max-content 1fr max-content;
}
