.toolbar {
  display: flex;
  padding: 8px;
  background: var(--text-area-toolbar-background-color);

  .toolbarBtnContainer {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
