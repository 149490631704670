.mobileHeader {
  background: var(--white);
  align-items: center;
  display: grid;
  grid-template-columns: max-content 1fr max-content;
  height: var(--top-bar-height);
  border-bottom: 1px solid var(--silver);
  box-shadow: 0px 1px 2px rgba(183, 177, 188, 0.5);
  position: relative;
  z-index: 1;

  @media (min-width: 640px) {
    display: none;
  }

  .header {
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 10px;
    text-transform: uppercase;
    margin: 0;
  }
}

.drawer {
  background-color: var(--student-nav-background-color);
  color: var(--white);

  .drawerHeader {
    padding-top: 5px;
    padding-left: 5px;
    display: flex;
    align-items: center;
  }

  .title {
    margin-left: 10px;
    text-transform: capitalize;

    &:first-of-type {
      margin-top: 10px;
    }
  }

  .selectedListItem {
    background-color: var(--green-primary);
  }
}
